import {
  useEffect,
  useState,
} from 'react';

import {
  Card,
  CardGroup,
  Col,
  Container,
  Image,
  Row,
} from 'react-bootstrap';
import { BiUserCheck } from 'react-icons/bi';
import { IoMdLogIn } from 'react-icons/io';

import * as config from '../config.js';

export const Denied = ({ query }) => {

    let [hasMessage, setHasMessage] = useState(false);

    useEffect(() => {

        console.log("Query", query);
        if (query && query.code) {
            setHasMessage(false);
        } else {
            setHasMessage(true);
        }


    }, [query]);

    return (<>

        <Container>
            <Row className="mt-4">
                <Col className='offset-lg-2 col-lg-8'>
                    {hasMessage ?
                        <LoginBox />
                        :
                        <LoadingScreen />
                    }

                </Col>
            </Row>
        </Container>

    </>)
}

const LoadingScreen = () => {

    // should be too short for spinners
    return (
        <>&nbsp;</>
    );
}

const LoginBox = () => {

    return (
        <Card>
            <Card.Body>
                <Card.Title>You're almost there</Card.Title>
                <Card.Text className="mt-4">
                    We just have to confirm your identity before we can continue.
                </Card.Text>
                <h3>
                    <a className='btn btn-lg btn-outline-primary' href={config.LoginUrlParameter}><IoMdLogIn /> Confirm your identity</a>
                </h3>
            </Card.Body>
            <Card.Footer>
                <small className="text-muted">Questions? <a href="mailto:73dbcb5f.WMIC.onmicrosoft.com@amer.teams.ms">Drop us a line</a>!</small>
            </Card.Footer>
        </Card>
    );

}