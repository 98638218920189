import 'bootstrap';

import * as React from 'react';

import * as ReactDOM from 'react-dom/client';

import { LinkAdmin } from './app';

let baseUrl = "https://" + window.location.host;
let params = new URLSearchParams(window.location.search);
let entries = params.entries();
let query = Object.fromEntries(entries);
let isDesktop = !(window.matchMedia("(max-width: 480px)").matches);
console.info("Starting up");

ReactDOM.createRoot(document.getElementById("app")).render(
    <LinkAdmin isDesktop={isDesktop} query={query} baseUrl={baseUrl} />
);
