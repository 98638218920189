import React, {
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import {
  Col,
  Container,
  Image,
  Navbar,
  Row,
} from 'react-bootstrap';
import {
  BsBarChartLine,
  BsCartCheck,
  BsCloudDownload,
  BsGearFill,
  BsStar,
} from 'react-icons/bs';
import { VscColorMode } from 'react-icons/vsc';
import {
  BrowserRouter,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { BrokerView } from './components/broker.jsx';
import { Dashboard } from './components/dashboard.jsx';
import { Denied } from './components/denied.jsx';
import { PageHeader } from './components/header.jsx';
import { BrokerPipeline } from './components/pipeline.jsx';
import { Welcome } from './components/welcome.jsx';
import * as config from './config';
import * as config from './config.js';
import * as api from './libs/api';
import { getBrokers } from './libs/api';
import { fetchTokens } from './libs/auth';
import { getCredentials } from './libs/auth.js';
import {
  LayoutContext,
  SessionContext,
  stateReducer,
} from './libs/contexts';

//import * as ui from './libs/ui';

export const LinkAdmin = ({ isDesktop, query, baseUrl }) => {

    const [session, setSession] = useReducer(stateReducer, {});
    const [layout, setLayout] = useReducer(stateReducer, {});
    const [notifications] = useState({});
    const [initialized, setInitialized] = useReducer(stateReducer, {
        layout: false,
        session: false
    });
    const [authorizationExpiration, setAuthorizationExpiration] = useState(0);
    const [isAuthorized, setIsAuthorized] = useState(false);
    const [showMessage, setShowMessage] = useState(false);




    useEffect(() => {


        console.info("Base url", baseUrl);

        if (undefined === layout || undefined === layout.name) {
            setLayout({ isDesktop: isDesktop });
            setInitialized({ layout: true });
        }

        const onSessionData = ({ sessionData }) => {

            sessionData.type = "session";


            //dev
            //sessionData.first_name = "Ted";
            //sessionData.last_name = "Cetera";
            //sessionData.email = "jlmartel@wawanesa.com";
            //sessionData.phone = "(204) 222-3333";
            //sessionData.premium = "$18.30";
            //sessionData.facevalue = 123456;
            //sessionData.dob = "1980-01-01";
            //sessionData.term = "10";
            //sessionData.age = 43;
            //sessionData.disqualified = 'mismatch';
            setSession(sessionData);
            setInitialized({ session: true });

        }

        if (undefined === session || undefined === session.id) {

            //setSession({ username: 'jlmartel@wawanesa.com' });
            //setInitialized({ session: false });
            // api.createSession({
            //     next: (sessionData) => onSessionData({ sessionData: sessionData })
            // });

        }

    }, []);


    // this is where we sent latest local changes to the cloud
    useEffect(() => {

        // if (session.asOf) {

        //     const syncSession = () => {
        //         if (session.quote) {
        //             api.saveSession({
        //                 quote: session, token: session.token, next: (data) => {
        //                     if (!saved) {
        //                         console.info("Our session was updated...", data);
        //                         setSaved(true);
        //                     }
        //                 }
        //             });
        //         }
        //     }

        //     const timer = setInterval(() => syncSession(), 5000);
        //     return () => clearInterval(timer);
        // }
    }, [session.asOf]);

    useEffect(() => {


        const createSession = ({ sessionInfo }) => {

            setSession({
                email: sessionInfo.email,
                token: sessionInfo.access_token,
                auth: sessionInfo
            });
            setAuthorizationExpiration(sessionInfo.exp);
            setIsAuthorized(true);
            setInitialized({ session: true });
            localStorage.setItem("session", JSON.stringify(sessionInfo));
        }


        if (0 === authorizationExpiration) {

            if (undefined !== query) {

                if (query.code) {
                    setShowMessage(false);
                    fetchTokens({
                        code: query.code,
                        redirect: baseUrl + "/welcome",
                        client: config.LinkAdminPoolClientParameter,
                        endpoint: config.LoginUrlParameter.split('oauth2').shift(),
                        next: (sessionInfo) => {
                            createSession({ sessionInfo: sessionInfo });
                        }
                    });
                } else {
                    setShowMessage(true);
                    let cachedSession = localStorage.getItem("session");
                    console.info("Got cached session", cachedSession);
                    if (cachedSession) {
                        let newSession = JSON.parse(cachedSession);
                        if (newSession && newSession.exp) {
                            if (Date.now() < (1000 * newSession.exp)) {
                                console.info("Valid session");
                                createSession({ sessionInfo: newSession });
                            } else {
                                console.info("Expired session");
                                console.log(1000 * newSession.exp);
                                console.log(Date.now());
                            }

                        }
                    }
                }
            }
        }
    }, [authorizationExpiration, query]);

    return (
        <SessionContext.Provider value={{ session, setSession }}>
            <LayoutContext.Provider value={{ layout, setLayout }}>
                <>
                    {initialized && initialized.session ?
                        <>
                            <LinkAdminRoutes />
                            {/* <BrokerPipeline /> */}
                        </>
                        :
                        <>
                            <Denied query={query} />
                        </>
                    }
                </>
            </LayoutContext.Provider>
        </SessionContext.Provider>

    );
}

const Page = ({ query, desktop }) => {



    return (
        <>
            <PageHeader />
            <Container fluid className="mb-4">
                <Row>
                    <Col>
                        <Outlet />
                    </Col>
                </Row>
            </Container>
        </>
    );
}

const LinkAdminRoutes = ({ query, desktop }) => {

    const { session, setSession } = useContext(SessionContext);

    useEffect(() => {
        getBrokers({
            token: session.token,
            next: (data) => {
                console.info("brokers", data);
                setSession({
                    brokers: data
                });
            },
            verbose: false
        });
    }, []);

    useEffect(() => {
        if (session && session.asOf && session.asOf > 1) {
            console.info("As of", session);
            // getBrokers({
            //     token: session.token,
            //     next: (data) => {
            //         console.info("brokers", data);
            //         setSession({
            //             brokers: data
            //         });
            //     },
            //     verbose: false
            // })
        }
    }, [session.asOf]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Page query={query} desktop={desktop} />}>
                    <Route index element={<Dashboard />} />
                    <Route path="login" element={<Dashboard />} />
                    <Route path="welcome" element={<Welcome />} />
                    <Route path="brokers/:brokerId" element={<BrokerView />} />
                    <Route path="*" element={<Dashboard />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );

}