import React, { useEffect } from 'react';

import { Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const Welcome = () => {

    const navigate = useNavigate();

    useEffect(() => {
        navigate('/');
    }, []);

    return (
        <Spinner animation="grow" variant="primary" />
    );
}
