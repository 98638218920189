import {
  React,
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Col,
  Container,
  Image,
  Nav,
  Navbar,
  NavDropdown,
  NavItem,
  Row,
} from 'react-bootstrap';
import { BiMapPin } from 'react-icons/bi';
import { BsBarChartSteps } from 'react-icons/bs';
import {
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import logo from '../css/logo.png';
import { SessionContext } from '../libs/contexts';
import { sortBrokers } from '../libs/utils';

export const PageHeader = () => {

    const { session } = useContext(SessionContext);

    const [brokers, setBrokers] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {

        if (session.brokers) {
            let newBrokers = sortBrokers({ brokers: session.brokers });
            setBrokers(newBrokers);
        }

    }, [session.brokers]);



    return (
        <>
            <Navbar expand="lg" bg="primary" text="white" variant="dark">

                <Container>
                    <Navbar.Collapse id="responsive-navbar-nav">
                        <Nav className="me-auto">

                        </Nav>
                        <Nav>
                            <Nav.Link eventKey={2} href="mailto:73dbcb5f.WMIC.onmicrosoft.com@amer.teams.ms">
                                Help
                            </Nav.Link>

                            <NavDropdown title="Downloads" id="collapsible-nav-dropdown2">
                                <NavDropdown.Item href="https://wmic.sharepoint.com/:b:/s/Life-LinkDevelopment/EQ9n8Dveag5PsDDYH3PCtYIBM9pqYNNzflEfVkijucWsRQ?e=rdwmm6">
                                    Sandbox PDF
                                </NavDropdown.Item>
                                <NavDropdown.Item href="https://wmic.sharepoint.com/:b:/s/Life-LinkDevelopment/EWg0xxl5DRhCgGLukg4N_aUBtpKxl2t8yNfSFPizadLnZA?e=fltc3y">
                                    Quick Term product sheet
                                </NavDropdown.Item>
                            </NavDropdown>

                            {"/" === location.pathname ?
                                <>
                                    {brokers &&
                                        <NavDropdown title="Brokers" id="collapsible-nav-dropdown">
                                            {brokers.map((broker, index) => (

                                                <NavDropdown.Item as={NavItem} key={index}>
                                                    <Link className="dropdown-item" to={"brokers/" + broker.id}>
                                                        {broker.name}</Link>
                                                </NavDropdown.Item>

                                            ))}
                                            <NavDropdown.Divider />
                                            <NavDropdown.Item as={NavItem}>
                                                <Link className="dropdown-item" to="/">Onboarding pipeline</Link>
                                            </NavDropdown.Item>
                                        </NavDropdown>
                                    }
                                </>
                                :
                                <Nav.Link eventKey={3} as={NavItem}>
                                    <Link className="dropdown-item" to="/">Onboarding pipeline</Link>
                                </Nav.Link>

                            }

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <Container>
                <Row className="mt-3">
                    <Col>
                        <Image src={logo} />
                    </Col>
                </Row>
            </Container>
        </>
    )
}


export const SectionHeader = ({ title }) => {

    return (
        <Container>
            <Row className="mt-4">
                <Col>
                    <h1 className='fancy-title'>{title}</h1>
                </Col>
            </Row>
        </Container>
    );
}