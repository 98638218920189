import {
  useContext,
  useEffect,
} from 'react';

import {
  Badge,
  Card,
  Col,
  Container,
  ListGroup,
  Row,
} from 'react-bootstrap';

import {
  getBrokers,
  getReports,
} from '../libs/api';
import { SessionContext } from '../libs/contexts';
import {
  PageHeader,
  SectionHeader,
} from './header';
import { BrokerPipeline } from './pipeline';

export const BrokerTraffic = () => {
    const { session, setSession } = useContext(SessionContext);

    useEffect(() => {
        console.info(session.reports);
    }, [session.reports]);

    return (<>

        {session.reports && session.reports.obj ?
            <Container>
                <Row><Col className="col-md-6 offset-md-3">
                    <ListGroup>
                        {session.reports.obj.all_time.map((row, index) => (
                            <ListGroup.Item key={index}>
                                <h5>{row.broker}</h5>
                                {row.leads} leads
                                <small className="text-muted float-end">{row.anonymous} anonymous visitors</small>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
                </Row>
            </Container>
            :
            <>...</>}
    </>);
}
