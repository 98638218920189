import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Dropdown,
  Form,
  Image,
  Row,
  Spinner,
} from 'react-bootstrap';
import { BsSpellcheck } from 'react-icons/bs';
import {
  useLocation,
  useParams,
} from 'react-router-dom';

import SupportImage from '../css/customer-service.jpg';
import BrandingImage from '../css/designers2.jpg';
import SalesImage from '../css/salesperson2.jpg';
import WorriedImage from '../css/worried.jpg';
import { updateBroker } from '../libs/api';
import { SessionContext } from '../libs/contexts';
import { pipelineStatus } from '../libs/ui';

export const BrokerView = () => {

    const { session } = useContext(SessionContext);
    let { brokerId } = useParams();

    const location = useLocation();

    const [brokerInfo, setBrokerInfo] = useState(false);
    const [hasAdminAccess, setHasAdminAccess] = useState(false);
    const [dataElement, setDataElement] = useState('grid');

    useEffect(() => {
        if (session && session.email && session.brokers) {
            if (session.brokers[brokerId]) {
                let newBrokerInfo = { ...session.brokers[brokerId] };
                if (newBrokerInfo.admins && newBrokerInfo.admins.includes(session.email)) {
                    setHasAdminAccess(true);
                } else {
                    setHasAdminAccess(false);
                }
                setBrokerInfo(newBrokerInfo);
            }
        }
    }, [session.email, session.brokers, brokerId, location]);

    const getBack = (e) => {
        setDataElement('grid');
    }

    return (
        <>
            {brokerInfo ?
                <>
                    {'grid' === dataElement ?
                        <GridView brokerInfo={brokerInfo} hasAdminAccess={hasAdminAccess} setDataElement={setDataElement} />
                        :
                        <>
                            {'template' === dataElement ?
                                <TemplateView brokerInfo={brokerInfo} getBack={getBack} />
                                :
                                <FormView brokerInfo={brokerInfo} dataElement={dataElement} getBack={getBack} />

                            }
                        </>
                    }

                </>
                :
                <>
                    <Spinner variant="primary" animation="grow" />
                </>
            }

        </>
    );
}

const FormView = ({ brokerInfo, dataElement, getBack }) => {

    const { session, setSession } = useContext(SessionContext);
    const [updated, setUpdated] = useState(false);
    const [notifications, setNotifications] = useState();
    const [sidebar, setSidebar] = useState();
    const [analytics, setAnalytics] = useState();
    const [branding, setBranding] = useState();
    const [phone, setPhone] = useState();
    const [email, setEmail] = useState();

    useEffect(() => {
        setNotifications(brokerInfo.notifications);
        setSidebar(brokerInfo.sidebar);
        setAnalytics(brokerInfo.analytics);
        setBranding(brokerInfo.branding);
        setEmail(brokerInfo.email);
        setPhone(brokerInfo.phone);
    }, [brokerInfo]);


    useEffect(() => {

        if (updated) {


            let newData = {
                email: email,
                phone: phone,
                analytics: analytics,
                sidebar: sidebar,
                notifications: notifications,
                branding: branding
            };
            let sanitizedData = {};
            for (let [k, v] of Object.entries(newData)) {
                if (undefined !== v && "undefined" !== v && "" !== v && null !== v) {
                    sanitizedData[k] = v;
                    brokerInfo[k] = v;
                }
            }

            updateBroker({
                token: session.token,
                id: brokerInfo.id,
                data: sanitizedData,
                next: (data) => {
                    console.info("updated broker", data);
                    setUpdated(false);
                    setSession({ asOf: Date.now() });
                    let brokerData = { ...session.brokers };
                    brokerData[brokerInfo.id] = brokerInfo;
                    setSession({ brokers: brokerData });
                    getBack();
                },
                verbose: false
            });
        }
    }, [brokerInfo, updated, analytics, branding, email, notifications, phone, sidebar]);

    const updateField = (e) => {

        switch (e.target.id) {
            case 'analytics':
                setAnalytics(e.target.value);
                break;
            case 'branding':
                setBranding(e.target.value);
                break;
            case 'email':
                setEmail(e.target.value);
                break;
            case 'notifications':
                setNotifications(e.target.value);
                break;
            case 'phone':
                setPhone(e.target.value);
                break;
            case 'sidebar':
                setSidebar(e.target.value);
                break;
        }

    }

    return (
        <>
            {brokerInfo &&
                <Container>
                    <Row className='mt-4'>
                        <Col className='col-md-6 offset-md-3'>

                            <Form>



                                {'branding' === dataElement &&
                                    <>
                                        <Form.Group className="mb-3" controlId="analytics">
                                            <Form.Label>Analytics</Form.Label>
                                            <Form.Control onChange={updateField} type="text" value={analytics || ''} placeholder="Analytics" />
                                            <Form.Text className="text-muted">
                                                Google Analytics code or other relevant information.
                                            </Form.Text>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="branding">
                                            <Form.Label>Branding guidelines</Form.Label>
                                            <Form.Control onChange={updateField} type="text" value={branding || ''} placeholder="Branding guidelines" />
                                            <Form.Text className="text-muted">
                                                Summarize the approach and whether formal guidelines are available.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="sidebar">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control onChange={updateField} type="text" value={sidebar || ''} placeholder="Sidebar" />
                                            <Form.Text className="text-muted">
                                                What to display in the sidebar: photos, maps, or video.
                                            </Form.Text>
                                        </Form.Group>

                                    </>
                                }


                                {'contact' === dataElement &&
                                    <>
                                        <Form.Group className="mb-3" controlId="email">
                                            <Form.Label>Email</Form.Label>
                                            <Form.Control onChange={updateField} type="text" value={email || ''} placeholder="Email address" />
                                            <Form.Text className="text-muted">
                                                Email address to display on the website.
                                            </Form.Text>
                                        </Form.Group>

                                        <Form.Group className="mb-3" controlId="phone">
                                            <Form.Label>Phone</Form.Label>
                                            <Form.Control onChange={updateField} type="text" value={phone || ''} placeholder="Phone number" />
                                            <Form.Text className="text-muted">
                                                Phone number to display on the website.
                                            </Form.Text>
                                        </Form.Group>
                                    </>
                                }

                                {'notifications' === dataElement &&
                                    <Form.Group className="mb-3" controlId="notifications">
                                        <Form.Label>Email address(es)</Form.Label>
                                        <Form.Control onChange={updateField} type="text" value={notifications || ''} placeholder="Enter email address(es)" />
                                        <Form.Text className="text-muted">
                                            Use comma or space when entering multiple email addresses.
                                        </Form.Text>
                                    </Form.Group>
                                }


                                <Button onClick={() => setUpdated(true)} variant="outline-success" type="button">
                                    Save
                                </Button>&nbsp;
                                <Button variant="outline-danger" type="button" onClick={getBack}>
                                    Cancel
                                </Button>
                            </Form>


                        </Col>

                    </Row>
                </Container>
            }
        </>
    );


}

const GridView = ({ brokerInfo, hasAdminAccess, setDataElement }) => {

    const { session, setSession } = useContext(SessionContext);
    const changeStatus = (e) => {

        brokerInfo.stage = e.target.id;

        updateBroker({
            token: session.token,
            id: brokerInfo.id,
            data: { stage: e.target.id },
            next: (data) => {
                let brokerData = { ...session.brokers };
                brokerData[brokerInfo.id] = brokerInfo;
                setSession({ brokers: brokerData });
            },
            verbose: false
        });

    }

    return (<>
        {brokerInfo &&
            <Container>
                <Row className="mt-4 mb-4">
                    <Col>
                        <h1>{brokerInfo.name}
                            <Dropdown className="float-end">
                                <Dropdown.Toggle variant="outline-success" id="dropdown-basic">
                                    {brokerInfo.stage}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {pipelineStatus.map((status, index) => (
                                        <React.Fragment key={index}>
                                            {brokerInfo.stage !== status.id &&
                                                <Dropdown.Item id={status.id} onClick={changeStatus}>
                                                    Move to {status.name}
                                                </Dropdown.Item>
                                            }
                                        </React.Fragment>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </h1>
                        <hr />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <CardGroup>
                            <Card bg="light" border="warning" >
                                <Card.Img variant="top" src={WorriedImage} />
                                <Card.Body>
                                    <Card.Title>Email template.</Card.Title>
                                    <Card.Text>
                                        Not sure how to ask the broker's team for what's missing in their Link profile?
                                        Click the button below to get a customized email template.
                                    </Card.Text>
                                    <Button variant="outline-primary" onClick={() => setDataElement('template')}>Generate template</Button>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={SalesImage} />
                                <Card.Body>
                                    <Card.Title>Broker notifications</Card.Title>
                                    <Card.Text>
                                        Brokers can currently get email notifications on every lead, as well as daily and weekly reports.
                                        Click the button below to adjust those settings.
                                    </Card.Text>
                                    <Button variant="outline-primary" onClick={() => setDataElement('notifications')}>Manage notifications</Button>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={BrandingImage} />
                                <Card.Body>
                                    <Card.Title>Branding</Card.Title>
                                    <Card.Text>
                                        Brokers are usually very careful about their brand, including colors, fonts and logos.
                                        Click the button below to adjust those settings.
                                    </Card.Text>
                                    <Button variant="outline-primary" onClick={() => setDataElement('branding')}>Manage branding</Button>
                                </Card.Body>
                            </Card>
                            <Card>
                                <Card.Img variant="top" src={SupportImage} />
                                <Card.Body>
                                    <Card.Title>Contact information</Card.Title>
                                    <Card.Text>
                                        Customers who have questions about their needs should contact the originating broker.
                                        Click the button below to provide contact information.
                                    </Card.Text>
                                    <Button variant="outline-primary" onClick={() => setDataElement('contact')}>Edit contact info</Button>
                                </Card.Body>
                            </Card>
                        </CardGroup>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col className="text-center text-muted">
                        <small><BsSpellcheck /> Don't worry too much about making typos or errors. The information you provide will be reviewed by our IT experts.</small>
                    </Col>
                </Row>
            </Container>

        }
    </>);
}


const TemplateView = ({ brokerInfo, getBack }) => {


    const [message, setMessage] = useState('');

    useEffect(() => {

        if (brokerInfo) {
            let fields = [
                { phone: "Phone number to be displayed on the website" },
                { email: "Email address to be displayed on the website" },
                { branding: "Branding guidelines" },
                { analytics: "Google Analytics code (if applicable)" },
                { sidebar: "Sidebar content" },
                { notifications: "Email addresses where new lead notifications should be sent" }
            ];
            let missing = [];
            let found = [];
            for (let field of fields) {
                for (let [k, v] of Object.entries(field)) {
                    if (brokerInfo[k] && brokerInfo[k].length > 2) {
                        found.push(v.toString() + ": " + brokerInfo[k]);
                    } else {
                        missing.push(v.toString());
                    }
                }
            }
            let newMessageLines = ["Dear [broker]"];
            if (missing.length > 0) {
                newMessageLines.push("");
                newMessageLines.push("We need the following information to fully customize your Link environment:");
                for (let item of missing) {
                    newMessageLines.push(`-${item}`);
                }
            }
            newMessageLines.push("");
            if (found.length > 0) {
                newMessageLines.push("");
                newMessageLines.push("We already have following information. Hopefully this is correct:");
                for (let item of found) {
                    newMessageLines.push(`-${item}`);
                }
            }
            newMessageLines.push("");
            newMessageLines.push("");
            newMessageLines.push("Thank you!");
            newMessageLines.push("[Your Favorite RLM]");
            let newMessage = newMessageLines.join("\r\n");
            setMessage(newMessage);
        }

    }, [brokerInfo]);



    return (<>

        <Container>
            <Row className='mt-4'>
                <Col className="col-md-6 offset-md-3">
                    <Card>
                        <Card.Header>
                            Email template
                        </Card.Header>
                        <Card.Body>
                            <pre>
                                {message}
                            </pre>
                        </Card.Body>
                        <Card.Footer>
                            Remember to double-check the text for <b>[placeholders]</b> before sending
                        </Card.Footer>
                    </Card>

                </Col>
            </Row>
            <Row className="mt-3">
                <Col className="col-md-6 offset-md-3">
                    <Button variant="outline-primary" onClick={getBack}>Close</Button>
                </Col>
            </Row>
        </Container>

    </>)
}
