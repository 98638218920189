import * as config from '../config';
import { sendRequest } from './restClient';

export const version = 2;

const query = ({ uri, token, next, verbose }) => {

    let params = {
        url: config.gateway + '/' + uri,
        callback: next,
        token: token,
        verbose: verbose
    }


    let success = sendRequest(params);

    return success;

}

const save = ({ uri, data, token, next, verbose }) => {

    let params = {
        data: data,
        method: 'post',
        url: config.gateway + '/' + uri,
        callback: next,
        token: token,
        verbose: false
    }

    let success = sendRequest(params);

    return success;

}



export const updateBroker = ({ id, data, token, next, verbose }) => {

    const callbackTasks = (outcome) => {

        if (verbose) {
            //console.info("update", outcome);
        }

        if (next) {
            next(data);
        }
    }

    let sanitizedData = {};

    data.category = 'broker';
    data.id = id;

    for (let [k, v] of Object.entries(data)) {
        if (undefined !== v && "undefined" !== v && "" !== v && null !== v) {
            sanitizedData[k] = v;
        }
    }

    let success = save({
        data: sanitizedData,
        token: token,
        next: callbackTasks,
        uri: 'brokers',
        verbose: false
    });

    return success;

}

export const getData = ({ token, next, verbose }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            //console.info("Downloaded", data);
        }

        if (next) {
            next(data);
        }
    }

    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'data',
        verbose: false
    });

    return success;

}

export const getBrokers = ({ token, next, verbose }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            //console.info("Refreshed", data);
        }

        if (next) {
            next(data);
        }
    }

    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'brokers',
        verbose: false
    });

    return success;

}


export const getReports = ({ token, next, verbose }) => {

    const callbackTasks = (data) => {

        if (verbose) {
            //console.info("Refreshed", data);
        }

        if (next) {
            next(data);
        }
    }

    let success = query({
        token: token,
        next: callbackTasks,
        uri: 'reports',
        verbose: false
    });

    return success;

}

export const getLead = ({ id, next }) => {
    next({});
}


