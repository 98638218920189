
export const pipelineStatus = [
    {
        name: 'Considered',
        bg: 'secondary',
        text: 'white',
        id: 'considered'
    },
    {
        name: 'Interested',
        bg: 'primary',
        text: 'white',
        id: 'interested'
    },
    {
        name: 'Committed',
        bg: 'info',
        text: 'white',
        id: 'committed'
    },
    {
        name: 'Confirmed',
        bg: 'warning',
        text: 'black',
        id: 'confirmed'
    },
    {
        name: 'Participating',
        bg: 'success',
        text: 'white',
        id: 'participating'
    },
    {
        name: 'Ended',
        bg: 'danger',
        text: 'white',
        id: 'ended'
    }
];