

export const sortBrokers = ({ brokers }) => {

    const compare = (a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    }

    let sortedBrokers = [];

    if (brokers) {
        for (let [k, v] of Object.entries(brokers)) {
            let broker = { ...v };
            broker.id = k;
            sortedBrokers.push(broker);
        };
        sortedBrokers.sort(compare);
    }

    return sortedBrokers;

}