import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  Image,
  Row,
} from 'react-bootstrap';
import {
  BsChevronCompactLeft,
  BsChevronCompactRight,
} from 'react-icons/bs';
import {
  TbChevronsLeft,
  TbChevronsRight,
} from 'react-icons/tb';
import {
  Link,
  useNavigate,
} from 'react-router-dom';

import { SessionContext } from '../libs/contexts';
import { pipelineStatus } from '../libs/ui';
import { sortBrokers } from '../libs/utils';

export const BrokerPipeline = () => {

    const { session } = useContext(SessionContext);
    const [brokers, setBrokers] = useState([]);
    const [canEdit, setCanEdit] = useState(false);

    const stages = useMemo(
        () => {
            let columns = pipelineStatus;
            return columns;
        },
        []
    );

    useEffect(() => {


        if (session.brokers) {
            let newBrokers = sortBrokers({ brokers: session.brokers });
            setBrokers(newBrokers);
        }


    }, [session.brokers]);

    const onChange = ({ id, stage }) => {
        console.info("Changing", id, stage);
    }

    return (<>
        <Container>
            <Row>
                <Col>
                    <CardGroup>
                        {stages.map((value, key) => (
                            <Card key={key}>
                                <Card.Header className={`bg-${value.bg} text-${value.text}`}>
                                    {value.name}
                                </Card.Header>
                                <Card.Body>
                                    {brokers.map((broker, index) => (
                                        <React.Fragment key={index}>
                                            {value.id === broker.stage &&
                                                <PipelineItem name={broker.name} id={broker.id} admin={broker.admins && broker.admins.includes(session.email)} />
                                            }
                                        </React.Fragment>
                                    ))}
                                </Card.Body>
                            </Card>
                        ))}
                    </CardGroup>
                </Col>
            </Row>
        </Container>
    </>)


}

const PipelineItem = ({ name, id, admin }) => {

    const navigate = useNavigate();

    return (
        <Card className={admin ? "mb-2 text-center pipeline-write" : "mb-2 text-center pipeline-read"}>
            <Card.Body onClick={() => navigate('/brokers/' + id)}>{name}</Card.Body>
        </Card>
    )
}