import {
  useContext,
  useEffect,
} from 'react';

import {
  Card,
  Col,
  Container,
  Row,
  Spinner,
} from 'react-bootstrap';

import { getBrokers } from '../libs/api';
import { SessionContext } from '../libs/contexts';
import {
  PageHeader,
  SectionHeader,
} from './header';
import { BrokerTraffic } from './metrics';
import { BrokerPipeline } from './pipeline';

export const Dashboard = () => {
    const { session, setSession } = useContext(SessionContext);

    // useEffect(() => {
    //     getBrokers({
    //         token: session.token,
    //         next: (data) => {
    //             console.info("brokers", data);
    //             setSession({
    //                 brokers: data
    //             });
    //         },
    //         verbose: false
    //     });
    // }, []);

    useEffect(() => {
    }, [session]);

    return (<>

        {session.brokers ?
            <>
                <SectionHeader title="Link onboarding pipeline" />
                <BrokerPipeline />
            </>
            :
            <Spinner variant="primary" animation="grow" />
        }
    </>);
}